// ** React Imports
import { ReactNode } from "react"

// ** MUI Components
import useMediaQuery from "@mui/material/useMediaQuery"
import { styled, useTheme } from "@mui/material/styles"

interface FooterIllustrationsProp {
  image?: ReactNode
}

// Styled Components
const MaskImg = styled("img")(() => ({
  bottom: 0,
  zIndex: -1,
  width: "100%",
  position: "absolute",
}))

const TreeImg = styled("img")(({ theme }) => ({
  left: "2.25rem",
  bottom: "4.25rem",
  position: "absolute",
  [theme.breakpoints.down("lg")]: {
    left: 0,
    bottom: 0,
  },
}))

const FooterIllustrations = (props: FooterIllustrationsProp) => {
  // ** Props
  const { image } = props

  // ** Hook
  const theme = useTheme()

  // ** Vars
  const hidden = useMediaQuery(theme.breakpoints.down("md"))

  if (!hidden) {
    return (
      <>
        {image || <TreeImg alt='tree' src='/images/pages/tree-2.png' />}
        <MaskImg alt='mask' src={`/images/pages/misc-mask-${theme.palette.mode}.png`} />
      </>
    )
  } else {
    return null
  }
}

export default FooterIllustrations
