// ** Redux Imports
import {createSlice, createAsyncThunk} from "@reduxjs/toolkit"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type * as rtk from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

interface DataParams {
  q: string
}

// ** Fetch Invoices
export const fetchData = createAsyncThunk("appPermissions/fetchData", async (params: DataParams) => {
  const response = await axios.get("/apps/permissions/data", {
    params,
  })

  return response.data
})

export const appPermissionsSlice = createSlice({
  name: "appPermissions",
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchData.fulfilled, (state, action) => {
      state.data = action.payload.permissions
      state.params = action.payload.params
      state.allData = action.payload.allData
      state.total = action.payload.total
    })
  },
})

export default appPermissionsSlice.reducer
