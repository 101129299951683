// ** React Imports
import { ReactNode, useEffect } from "react"

// ** Next Import
import { useRouter } from "next/router"

// ** Types
import type { ACLObj, AppAbility } from "@wildeye/frontend/src/configs/acl"

// ** Context Imports
import { AbilityContext } from "@wildeye/frontend/src/layouts/components/acl/Can"

// ** Config Import
import { buildAbilityFor } from "@wildeye/frontend/src/configs/acl"

// ** Component Import
import NotAuthorized from "@wildeye/frontend/src/pages/401"
import Spinner from "@wildeye/frontend/src/layouts/components/spinner"
import BlankLayout from "@wildeye/frontend/src/@core/layouts/BlankLayout"

// ** Hooks
import { useSession } from "next-auth/react"

// ** Util Import
import getHomeRoute from "@wildeye/frontend/src/layouts/components/acl/getHomeRoute"

interface AclGuardProps {
  children: ReactNode
  authGuard?: boolean
  guestGuard?: boolean
  aclAbilities: ACLObj
}

const AclGuard = (props: AclGuardProps) => {
  // ** Props
  const { aclAbilities, children, guestGuard = false, authGuard = true } = props

  // ** Hooks
  const session = useSession()
  const router = useRouter()

  // ** Vars
  let ability: AppAbility

  useEffect(() => {
    const role = session?.data?.user?.role
    if (role && !guestGuard && router.route === "/") {
      const homeRoute = getHomeRoute(role)
      router.replace(homeRoute).then(()=>{})
    }
  }, [session?.data?.user, guestGuard, router])

  // User is logged in, build ability for the user based on his role
  const role = session?.data?.user?.role
  if (role && !ability) {
    ability = buildAbilityFor(role, aclAbilities.subject)
    if (router.route === "/") {
      return <Spinner />
    }
  }

  // If guest guard or no guard is true or any error page
  if (guestGuard || router.route === "/404" || router.route === "/500" || !authGuard) {
    // If user is logged in and his ability is built
    if (session?.data?.user && ability) {
      return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
    } else {
      // If user is not logged in (render pages like login, register etc..)
      return <>{children}</>
    }
  }

  // Check the access of current user and render pages
  if (ability && session?.data?.user && ability.can(aclAbilities.action, aclAbilities.subject)) {
    if (router.route === "/") {
      return <Spinner />
    }

    return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
  }

  // Render Not Authorized component if the current user has limited access
  return (
    <BlankLayout>
      <NotAuthorized />
    </BlankLayout>
  )
}

export default AclGuard
