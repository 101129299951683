// ** React Imports
import { Fragment, SyntheticEvent, useContext, useState } from "react"

// ** Next Import
import { useRouter } from "next/router"

// ** MUI Imports
import Box from "@mui/material/Box"
import Menu from "@mui/material/Menu"
import Badge from "@mui/material/Badge"
import Avatar from "@mui/material/Avatar"
import Divider from "@mui/material/Divider"
import MenuItem from "@mui/material/MenuItem"
import { styled } from "@mui/material/styles"
import Typography from "@mui/material/Typography"

// ** Icon Imports
import Icon from "@wildeye/frontend/src/@core/components/icon"

// ** Context
import { signOut, useSession } from "next-auth/react"

// ** Type Imports
import { Settings } from "@wildeye/frontend/src/@core/context/settingsContext"

// ** Context Imports
import { AbilityContext } from "@wildeye/frontend/src/layouts/components/acl/Can"
import { AppAbilityAction, AppAbilitySubject } from "src/configs/acl"
import { capitalizeFirstLetter } from "src/@core/utils/format"
import DialogFullScreen from "../../warmlink/components/DialogFullScreen"

interface Props {
  settings: Settings
}

// ** Styled Components
const BadgeContentSpan = styled("span")(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: "50%",
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
}))

const UserDropdown = (props: Props) => {
  // ** Props
  const { settings } = props

  // ** States
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  // ** Hooks
  const router = useRouter()
  const { data: session } = useSession()
  const ability = useContext(AbilityContext)

  const loggedIn = !!session?.user

  // ** Vars
  const { direction } = settings

  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = (url?: string) => {
    if (url) {
      router.push(url)
    }
    setAnchorEl(null)
  }

  const styles = {
    py: 2,
    px: 4,
    width: "100%",
    display: "flex",
    alignItems: "center",
    color: "text.primary",
    textDecoration: "none",
    "& svg": {
      mr: 2,
      fontSize: "1.375rem",
      color: "text.primary"
    }
  }

  const handleLogin = async () => {
    await router.push("/login")
  }

  const handleLogout = () => {
    signOut({ callbackUrl: "/", redirect: false }).then(() => {
      router.asPath = "/"
    })
    handleDropdownClose()
  }
  const [openTourDialog, setOpenTourDialog] = useState(false)

  const userName = loggedIn ? session?.user?.name || "John Doe" : ""
  const userImage = session?.user?.image || "/images/avatars/1.png"
  const userRole = loggedIn ? capitalizeFirstLetter(session?.user?.role || "Admin") : ""

  const handleOpenTour = () => {
    setOpenTourDialog(true)
  }

  const handleCloseTour = () => {
    setOpenTourDialog(false)
  }

  return (
    <div>
      <Fragment>
        <Badge
          overlap='circular'
          onClick={handleDropdownOpen}
          sx={{ ml: 2, cursor: "pointer" }}
          badgeContent={<BadgeContentSpan />}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
        >
          <Avatar alt={userName} onClick={handleDropdownOpen} sx={{ width: 40, height: 40 }} src={userImage} />
        </Badge>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => handleDropdownClose()}
          sx={{ "& .MuiMenu-paper": { width: 230, mt: 4 } }}
          anchorOrigin={{ vertical: "bottom", horizontal: direction === "ltr" ? "right" : "left" }}
          transformOrigin={{ vertical: "top", horizontal: direction === "ltr" ? "right" : "left" }}
        >
          <Box sx={{ pt: 2, pb: 3, px: 4 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Badge
                overlap='circular'
                badgeContent={<BadgeContentSpan />}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
              >
                <Avatar alt={userName} src={userImage} sx={{ width: "2.5rem", height: "2.5rem" }} />
              </Badge>
              <Box sx={{ display: "flex", ml: 3, alignItems: "flex-start", flexDirection: "column" }}>
                <Typography sx={{ fontWeight: 600 }}>{userName}</Typography>
                <Typography variant='body2' sx={{ fontSize: "0.8rem", color: "text.disabled" }}>
                  {userRole}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Divider sx={{ mt: "0 !important" }} />
          {ability?.can(AppAbilityAction.Develop, "") && (
            <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose("/pages/user-profile/profile")}>
              <Box sx={styles}>
                <Icon icon='mdi:account-outline' />
                Profile
              </Box>
            </MenuItem>
          )}
          {ability?.can(AppAbilityAction.Read, AppAbilitySubject.Inbox) ? (
            <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose("/apps/email")}>
              <Box sx={styles}>
                <Icon icon='mdi:email-outline' />
                Inbox
              </Box>
            </MenuItem>
          ) : null}
          {ability?.can(AppAbilityAction.Read, AppAbilitySubject.Chat) ? (
            <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose("/apps/chat")}>
              <Box sx={styles}>
                <Icon icon='mdi:message-outline' />
                Chat
              </Box>
            </MenuItem>
          ) : null}
          <Divider />
          {session?.user && (
            <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose("/pages/account-settings/account")}>
              <Box sx={styles}>
                <Icon icon='mdi:cog-outline' />
                Settings
              </Box>
            </MenuItem>
          )}
          {ability?.can(AppAbilityAction.Read, AppAbilitySubject.Pricing) ? (
            <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose("/pages/pricing")}>
              <Box sx={styles}>
                <Icon icon='mdi:currency-usd' />
                Pricing
              </Box>
            </MenuItem>
          ) : null}
          {ability?.can(AppAbilityAction.Read, AppAbilitySubject.FAQ) ? (
            <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose("/pages/faq")}>
              <Box sx={styles}>
                <Icon icon='mdi:help-circle-outline' />
                FAQ
              </Box>
            </MenuItem>
          ) : null}
          <MenuItem sx={{ p: 0 }} onClick={handleOpenTour}>
            <Box sx={styles}>
              <Icon icon='mdi:bus' />
              Tour
            </Box>
          </MenuItem>
          <Divider />
          {session?.user && (
            <MenuItem
              onClick={handleLogout}
              sx={{ py: 2, "& svg": { mr: 2, fontSize: "1.375rem", color: "text.primary" } }}
            >
              <Icon icon='mdi:logout-variant' />
              Logout
            </MenuItem>
          )}

          {!session?.user && (
            <MenuItem
              onClick={handleLogin}
              sx={{ py: 2, "& svg": { mr: 2, fontSize: "1.375rem", color: "text.primary" } }}
            >
              <Icon icon='mdi:logout-variant' />
              Login
            </MenuItem>
          )}
        </Menu>
      </Fragment>
      <DialogFullScreen open={openTourDialog} onClose={handleCloseTour} title='Warmlink Tour'>
        <iframe
          src='https://app.supademo.com/demo/cm29dv1on0lxjvm5s4yg2iftg' // Replace with your Supademo link
          title='Warmlink Tour'
          width='100%'
          height='100%'
          style={{ border: "none" }}
          allowFullScreen
        />
      </DialogFullScreen>
    </div>
  )
}

export default UserDropdown
