import {published_unsub_warmlink_url} from "./constants"
import {isProduction} from "./index"
import {prisma} from "@wildeye/prisma"
import {Click, Offer, User} from "@prisma/client"

/**
 * Format a Wraplink URL for current service
 * @param wrap_id {string} This can be the ID/CODE or ALIAS
 */
export function makeWraplink(wrap_id: string): string {
  return `${process.env.NEXT_PUBLIC_WRAPLINK_ORIGIN}/${wrap_id}`
}

// Unqiue offer URL for users to click (in LinkedIn messages)
// that take them to Warmlink.io to be redirected to vendor site
export function makeWarmlink(click_id: string, offerTitle: string = ''): string {
  return `${process.env.NEXT_PUBLIC_WARMLINK_ORIGIN}/${click_id}${offerTitle ? '/' + offerTitle : ''}`
}

// For users who want to unsubscribe from LinkedIn messages
export function makeUnsubscribeLink(click_id: string) {
  return isProduction() ? `${published_unsub_warmlink_url}/${click_id}`
    : `${process.env.NEXT_PUBLIC_APP}/unsub/${click_id}`
}

export function makeHomeLink(): string {
  return `${process.env.NEXT_PUBLIC_HOME}`
}

// Caller supplies subid value to generate a Partnerstack link with subid.
// Typically, the subid would be a click_id.
// Subid MUST BE ALPHANUMERIC according to partnerstack doc
// https://docs.partnerstack.com/docs/step-3-tracking-conversions
export function makePartnerstackAffiliateSubidLink(partnerstack_url: string, subid: string): string {
  return `${partnerstack_url}?subid=${subid}`
}

/**
 * Issue store offer links for people visiting offers.
 *
 * Scenarios:
 * 1. [C] visit store anonymously after learning of warmlink, and visit offers.
 *      a. New click row (offer, warmer=founder, buyer=null)
 * 2. [B] I followed a referred warmlink (via LinkedIn) to store, and visit offers anonymously without joining first
 *      a. New click row (offer, warmer=cookie, profile=cookie)
 *      b. Persist the parent cookie for future offer visits
 *      c. When/if buyer joins assign their parent if null
 * 3. [EF] I followed a referred warmlink (via LinkedIn) to store, join/login, then visit offers
 *      a. Assign warmer as parent to new member
 *      b. New click row (offer, warmer, buyer) for all offers
 * 4. [F] I'm a member and visit offers.
 *      a. New click row (offer, warmer, buyer) for all offers
 * 5. [A] I visit someone else's warmlink to the store but already have a parent cookie with a warmer.
 *      a. When I visit an offer anonymously, I use the previous parent cookie.
 *
 * Rules:
 * A. Following warmlink only sets cookie if no parent cookie exists (does not overwrite previous).
 * B. If anonymous buyer clicks learn more, with 'parent' cookie, construct click record with warmer and buyer profile.
 * C. If anonymous buyer clicks learn more, with no 'parent' cookie, construct click record with founder as warmer.
 * D. The parent cookie will never be removed. It could be cleared by the browser though at any time.
 * E. When an anonymous user logs in/joins, and their parent_id is null, then set it to the warmer if 'parent' cookie available,
 *    or founder.
 * F. If buyer logged in and visits offer, construct click record with parent and buyer user id
 * G. Use or create click records as needed.
 * See Figma diagram for details on flow.
 *
 * Actions:
 * 1. Send warmlink (done)
 * 2. Click, receive, and handle warmlink (done) [app/api/v1/warmlink/[offercode]/route.ts]
 * 3. Visit offer anonymously (in progress, to test)
 * 3. Login and set parent (done) [nextauth]
 * 4. Login and visit offer as buyer (done, to test)
 */

export type FindClickParams = {
  offer: Offer
  warmerId: string
  buyer: User | null
  customerId: string | null
}

export type MakeStoreOfferLinkResult = {
  warmlink: string // Warmer clicks to copy and share that heads to vendor affiliate site
  learnMoreLink: string // Buyer clicks to visit affiliate site attributed to warmer
  learnMoreClick?: Click // For testing and debug
}

/**
 *
 * @param offer
 * @param warmerId
 * @param buyer
 * @param customerId
 * @returns {MakeStoreOfferLinkResult} - Link is warmlink to us first to count clicks then redirect
 */
export async function makeStoreOfferLink({offer, warmerId, buyer = null, customerId = null}: FindClickParams):
  Promise<MakeStoreOfferLinkResult> {

  // Prepare LearnMore Warmlinks
  let params = <FindClickParams>{
    offer: offer,
    warmerId: buyer ? buyer?.parent_id || warmerId : warmerId,
    buyer: buyer || null,
    customerId: buyer ? null : customerId
  }
  let learnMoreClick = await findClick(params)
  if (!learnMoreClick)
    learnMoreClick = await newClick(params)

  // Prepare Generic Warmlinks (warmer-offer)
  params = <FindClickParams>{
    offer: offer,
    warmerId: warmerId,
    buyer: null,
    customerId: customerId
  }
  let warmlinkClick = await findClick(params)
  if (!warmlinkClick)
    warmlinkClick = await newClick(params)

  return <MakeStoreOfferLinkResult>{
    warmlink: warmlinkClick ? makeWarmlink(warmlinkClick.id, offer.id) : '',
    learnMoreLink: learnMoreClick ? makeWarmlink(learnMoreClick.id, offer.id) : '',
    learnMoreClick: learnMoreClick ?? undefined
  }
}

async function findClick({offer, warmerId, buyer = null, customerId = null}: FindClickParams): Promise<Click | null> {
  try {
    return prisma.click.findFirst({
      where: {offer_id: offer.id, warmer_id: warmerId, customer_id: customerId, buyer_id: buyer?.id,}
    })
  } catch (err) {
    console.log(err)
    return null
  }
}

async function newClick({offer, warmerId, buyer, customerId}: FindClickParams): Promise<Click | null> {
  try {
    const click = await prisma.click.create({
      data: {
        offer_id: offer.id,
        warmer_id: warmerId,
        customer_id: customerId ?? null,
        buyer_id: buyer?.id ?? null,
        affiliate_link: ''
      }
    })
    // Update record with real affiliate link that contains the new click record id
    const affiliateLink = `${offer.affiliate_url}?subid=${click.id}`
    return await prisma.click.update({where: {id: click.id}, data: {affiliate_link: affiliateLink}})
  } catch (err) {
    console.error(err)
  }
  return null
}

// Return Founder's user id or empty string
export async function getFounderId(): Promise<string> {
  const user = await prisma.user.findUnique({where: {email: process.env.FOUNDER_EMAIL}})
  return user?.id || ''
}

