// ** MUI Imports
import Box, {BoxProps} from "@mui/material/Box"

// ** Configs
import themeConfig from "@wildeye/frontend/src/configs/themeConfig"

const FallbackSpinner = ({sx}: { sx?: BoxProps["sx"] }) => {
  return (
    <Box
      sx={{
        gap: 2,
        height: "100vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "center",
        ...sx
      }}
    >
      <img src={themeConfig.logo} alt='logo' height='40' style={{marginBottom:5}}/>
      <img src='/images/iconify-svg/svg-spinners--bars-fade.svg' alt='logo' width='72' height='72'/>
    </Box>
  )
}

export default FallbackSpinner
