import {Role} from "@wildeye/prisma/user-roles"
import {MARKETPLACE_PATH} from "@wildeye/shared"

/**
 *  Set Home URL based on User Roles
 */
const getHomeRoute = (role: string) => {
  switch (role) {
    case Role.admin:
      return MARKETPLACE_PATH
    case Role.member:
      return MARKETPLACE_PATH
  }
  return MARKETPLACE_PATH
}

export default getHomeRoute
