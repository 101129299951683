// ** MUI Imports
import Box from "@mui/material/Box"

// ** Type Import
import {Settings} from "@wildeye/frontend/src/@core/context/settingsContext"

// ** Components
import ModeToggler from "@wildeye/frontend/src/@core/layouts/components/shared-components/ModeToggler"
import UserDropdown from "@wildeye/frontend/src/layouts/components/UserDropdown"
import LanguageDropdown from "@wildeye/frontend/src/@core/layouts/components/shared-components/LanguageDropdown"
import NotificationDropdown, {
  NotificationsType,
} from "@wildeye/frontend/src/@core/layouts/components/shared-components/NotificationDropdown"
import ShortcutsDropdown, {
  ShortcutsType,
} from "@wildeye/frontend/src/@core/layouts/components/shared-components/ShortcutsDropdown"

// ** Hook Import
import {useSession} from "next-auth/react"
import {useContext} from "react"

// ** Context Import
import {AbilityContext} from "@wildeye/frontend/src/layouts/components/acl/Can"
import {AppAbilityAction, AppAbilitySubject} from "src/configs/acl"

interface Props {
  hidden: boolean
  settings: Settings
  saveSettings: (values: Settings) => void
}

const notifications: NotificationsType[] = [
  {
    meta: "Today",
    avatarAlt: "Flora",
    title: "Congratulation Flora! 🎉",
    avatarImg: "/images/avatars/4.png",
    subtitle: "Won the monthly best seller badge",
  },
  {
    meta: "Yesterday",
    avatarColor: "primary",
    subtitle: "5 hours ago",
    avatarText: "Robert Austin",
    title: "New user registered.",
  },
  {
    meta: "11 Aug",
    avatarAlt: "message",
    title: "New message received 👋🏻",
    avatarImg: "/images/avatars/5.png",
    subtitle: "You have 10 unread messages",
  },
  {
    meta: "25 May",
    title: "Paypal",
    avatarAlt: "paypal",
    subtitle: "Received Payment",
    avatarImg: "/images/misc/paypal.png",
  },
  {
    meta: "19 Mar",
    avatarAlt: "order",
    title: "Received Order 📦",
    avatarImg: "/images/avatars/3.png",
    subtitle: "New order received from John",
  },
  {
    meta: "27 Dec",
    avatarAlt: "chart",
    subtitle: "25 hrs ago",
    avatarImg: "/images/misc/chart.png",
    title: "Finance report has been generated",
  },
]

const shortcuts: ShortcutsType[] = [
  {
    title: "Calendar",
    url: "/apps/calendar",
    subtitle: "Appointments",
    icon: "mdi:calendar-month-outline",
  },
  {
    title: "Invoice App",
    url: "/apps/invoice/list",
    subtitle: "Manage Accounts",
    icon: "mdi:receipt-text-outline",
  },
  {
    title: "Users",
    url: "/apps/user/list",
    subtitle: "Manage Users",
    icon: "mdi:account-outline",
  },
  {
    url: "/apps/roles",
    title: "Role Management",
    subtitle: "Permissions",
    icon: "mdi:shield-check-outline",
  },
  {
    url: "/dashboard",
    title: "Dashboard",
    icon: "mdi:chart-pie",
    subtitle: "User Dashboard",
  },
  {
    title: "Settings",
    icon: "mdi:cog-outline",
    subtitle: "Account Settings",
    url: "/pages/account-settings/account",
  },
  {
    title: "Help Center",
    subtitle: "FAQs & Articles",
    icon: "mdi:help-circle-outline",
    url: "/pages/help-center",
  },
  {
    title: "Dialogs",
    subtitle: "Useful Dialogs",
    icon: "mdi:window-maximize",
    url: "/pages/dialog-examples",
  },
]

const AppBarContent = (props: Props) => {
  // ** Props
  const {settings, saveSettings} = props

  // ** Hook
  const session = useSession()
  const ability = useContext(AbilityContext)

  return (
    <Box sx={{display: "flex"}}>
      <Box gap={3} sx={{display: "flex", alignItems: "center"}}>
        {/*{!session.data?.user && <Typography ><Link href='/login'>Login</Link></Typography>}*/}
        {/*{session.data?.user && <Autocomplete hidden={hidden} settings={settings}/>}*/}
        {ability?.can(AppAbilityAction.Update, AppAbilitySubject.Language) ? (
          <LanguageDropdown settings={settings} saveSettings={saveSettings}/>
        ) : null}
        <ModeToggler settings={settings} saveSettings={saveSettings}/>
        {session.data?.user && (
          <>
            {ability?.can(AppAbilityAction.Read, AppAbilitySubject.Shortcuts) ? (
              <ShortcutsDropdown settings={settings} shortcuts={shortcuts}/>
            ) : null}
            {ability?.can(AppAbilityAction.Read, AppAbilitySubject.Notification) ? (
              <NotificationDropdown settings={settings} notifications={notifications}/>
            ) : null}
            <UserDropdown settings={settings}/>
          </>
        )}
      </Box>
    </Box>
  )
}

export default AppBarContent
