// ** React Imports
import * as React from 'react'

// ** MUI Imports
import Dialog from "@mui/material/Dialog"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"

// ** Icon Imports
import Icon from "../../@core/components/icon"
import Slide from "@mui/material/Slide"
import {TransitionProps} from "@mui/material/transitions"
import DialogActions from "@mui/material/DialogActions"
import Button from "@mui/material/Button"

export interface DialogFullScreenProps {
  open: boolean
  onClose: () => void
  saveAction?: boolean
  title: string | React.ReactNode
  children?: React.ReactNode
}

const DialogFullScreen: React.FC<DialogFullScreenProps> = (
  {open, onClose, title, saveAction = false, children}) => {
  return (
    <div>
      <Dialog
        fullScreen
        onClose={onClose}
        open={open}
        TransitionComponent={Transition}
      >
        <DialogTitle>
          {typeof title === 'string' ? (<Typography variant='h4' component='span'>{title}</Typography>) : (title)}
          <IconButton
            aria-label='close'
            onClick={onClose}
            sx={{top: 8, right: 10, position: "absolute", color: "grey.500"}}>
            <Icon icon='mdi:close'/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
        {saveAction && <DialogActions className='dialog-actions-dense'>
          <Button onClick={onClose}>Save changes</Button>
        </DialogActions>}
      </Dialog>
    </div>
  )
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props}>{props.children}</Slide>
})

export default DialogFullScreen
