/**
 * This component is a Warmlink icon that when clicked, copies a unique Warmlink
 * link to the clipboard for the user to paste elsewhere.
 *
 * A Warmlink is produced for any specific user-offer in the store.
 */
import * as React from "react"
import {ReactNode, useState} from "react"

import Icon from "../../@core/components/icon"
import clipboardCopy from 'clipboard-copy'
import {UserIncludes} from "@wildeye/shared/server"

import Box from "@mui/system/Box"
import IconButton from "@mui/material/IconButton"
import Snackbar, {SnackbarCloseReason} from "@mui/material/Snackbar"
import SnackbarContent from '@mui/material/SnackbarContent'
import Tooltip from "@mui/material/Tooltip"
import {SxProps, Theme} from "@mui/system"
import {usePostWarmlinkMutation} from "../../store/apps/api/warmlink"

type ShareWarmlinkProps = {
  offerId: string
  offerTitle: string
  user?: UserIncludes
  linkType?: 'warmlink' | 'link'
  sx?: SxProps<Theme>
}

export default function WarmlinkShareButton({
                                              user,
                                              offerId,
                                              offerTitle,
                                              linkType = 'warmlink',
                                              sx
                                            }: ShareWarmlinkProps) {

  // const theme = useTheme()
  const duration = 2200
  const backgroundColor = 'brown' // theme.palette.success.main
  const textColor = 'white'
  const linkTitle = linkType == 'warmlink' ? 'Warmlink' : 'Link'
  const [openSnackBar, setOpenSnackBar] = useState<ReactNode>(null)
  const [getWarmlink] = usePostWarmlinkMutation()

  const handleCopy = async (/*event: React.MouseEvent<HTMLSpanElement>*/) => {
    if (!user) return

    try {
      const warmlink = await getWarmlink(offerId).unwrap()
      clipboardCopy(warmlink).then(() => {
        // setOpenSnackBar(<span>Copied <b>{offerTitle}</b> Affiliate {linkTitle}</span>)
        setOpenSnackBar(<span>Copied link to clipboard</span>)
      }).catch((err) => {
        console.error('Could not copy text: ', err)
      })
    } catch (err) {
      console.error(err)
    }
  }

  function handleClose(event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) {
    if (reason === 'clickaway')
      return
    setOpenSnackBar(null)
  }

  const title = user ? `Share your ${offerTitle} Affiliate ${linkTitle}`
    : `Login to refer ${offerTitle}`

  return (
    <Box sx={{...sx}}>
      <Tooltip title={title} placement='top'>
        <IconButton
          onClick={handleCopy}
          sx={{
            borderRadius: '50%', // Make the button circular
            padding: '0px', // Adjust padding to control the icon size and spacing
            backgroundColor: 'white',
            '&:hover': {
              backgroundColor: 'white', // Maintain white background on hover
            },
          }}>
          <Icon icon='mdi:share-circle' fontSize={26} style={{color: backgroundColor}}/>
        </IconButton>
      </Tooltip>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        autoHideDuration={duration}
        open={!!openSnackBar}
        onClose={handleClose}>
        <SnackbarContent
          message={openSnackBar}
          sx={{
            backgroundColor: backgroundColor,
            color: textColor,
            justifyContent: 'center',
          }}
        />
      </Snackbar>
    </Box>
  )
}

